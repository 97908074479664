import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"

import UniversityFinalist from "../data/university.json"
import SmkFinalist from "../data/smk.json"

const ListFinalistPage = () => {
  return (
    <Layout>
      <SEO title="Finalist" />
      <div className="container mx-auto my-12 flex flex-col items-center">
        <div className="m-4 lg:m-12">
          <h1 className="text-xl lg:text-3xl font-bold text-green-500 text-center">
            Perguruan Tinggi
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {UniversityFinalist.map(data => (
              <Link to={`university/${data.id}`}>
                <div
                  key={data.id}
                  className="rounded-lg shadow-md hover:cu hover:shadow-xl flex flex-col items-center m-4 p-8 text-black"
                >
                  <div className="w-56 h-56 flex items-center justify-center">
                    <img
                      src={data["fotoProduk"]}
                      alt={`foto-produk-${data["namaTim"]}`}
                    />
                  </div>
                  <h2 className="font-bold text-center leading-10">
                    {data["namaTim"]}
                  </h2>
                  <p className="line-clamp-4 text-justify">
                    {data["deskripsiSingkat"]}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full flex justify-center items-center my-12">
            <a
              href="http://bit.ly/FavTeamPT"
              target="_blank"
              rel="norefferer"
            >
              <Button className="text-white bg-green-500 hover:bg-green-700">
                Vote Sekarang
              </Button>
            </a>
          </div>
        </div>
        <div className="m-4 lg:m-12">
          <h1 className="text-xl lg:text-3xl font-bold text-red-400 text-center">
            SMA/SMK
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {SmkFinalist.map(data => (
              <Link to={`high-school/${data.id}`}>
                <div
                  key={data.id}
                  className="rounded-lg shadow-md hover:cu hover:shadow-xl flex flex-col items-center m-4 p-8 text-black"
                >
                  <div className="w-56 h-56 flex items-center justify-center">
                    <img
                      src={data["fotoProduk"]}
                      alt={`foto-produk-${data["namaTim"]}`}
                      className="object-cover h-48 w-48"
                    />
                  </div>
                  <h2 className="font-bold text-center leading-10">
                    {data["namaTim"]}
                  </h2>
                  <p className="line-clamp-4 text-justify">
                    {data["deskripsiSingkat"]}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <div className="w-full flex justify-center items-center my-12">
            <a
              href="http://bit.ly/FavTeamSMASMK"
              target="_blank"
              rel="norefferer"
            >
              <Button className="text-white bg-red-400 hover:bg-red-700">
                Vote Sekarang
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ListFinalistPage
